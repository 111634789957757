/* eslint-disable jsx-a11y/anchor-is-valid */
import {SimCard, SimCardListResponse} from "../models/Models";
import {fetchSimCardList} from "../api";
import {RootState} from "../../../../setup";
import {ThemeProvider} from "@mui/material";
import {SectionHeader} from "../SectionHeader";
import React, {useEffect, useRef, useState} from 'react'
import {PageTitle} from "../../../../_metronic/layout/core";
import TopSliderMenu from "../components/topSliderMenu";
import AlertDismissible from "../../../components/alerts/AlertDismissible";
import {SetMultipleStatus} from "../components/setStatus/SetMultipleStatus";
import {SendTestSms} from "../components/sms/SendTestSms";
import {SetGroupAssignments} from "../components/groups/SetGroupAssignments";
import {SetContactAssignments} from "../components/contacts/SetContactAssignments";
import {getColumns, getRows, theme} from "./SimCardListDataGridConfig";
import {toolbarConfig, ToolbarWithQuickSearch} from "../../../components/datagrid/ToolbarWithQuickSearch";
import {DataGrid, GridColDef, GridColumnVisibilityModel, GridSelectionModel, GridSortModel, ruRU} from "@mui/x-data-grid";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {actions, ISettingsState} from "../../settings/redux/SettingsRedux";
import {Rest} from "../../../models/common";
import {OkResponse} from "../../../api/CommonApi";

const defaultSortModel: GridSortModel = [
    {
        field: 'msisdn',
        sort: 'asc',
    },
]

export const SimCardListPage = function () {
    const dispatch = useDispatch()
    const settings = useSelector<RootState>(({settings}) => settings, shallowEqual) as ISettingsState

    const columnVisibility = useRef(settings.simCardListPage.columnVisibility)

    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [searchText, setSearchText] = useState('');
    const [refreshTable, setRefreshTable] = useState(Date.now())
    const [hiddenCols, setHiddenCols] = useState<(string | null)[]>([])
    const [columns, setColumns] = useState<GridColDef[]>([])
    const [rows, setRows] = useState<SimCard[] | undefined>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [simCardList, setSimCardList] = useState<SimCard[] | undefined>([])
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>()

    useEffect(() => {
        const getSimCardList = async () => {
            setLoading(true)
            const response = await fetchSimCardList({})
            switch (response.status) {
                case "ok":
                    return setSimCardList(await requestSequencer(response) as SimCard[])
                default:
                    console.log(response)
                    setMessage("Сервер не смог обработать запрос. Попробуйте обновить страницу.")
                    return false
            }
        }

        getSimCardList()
    }, [refreshTable])

    // Подгрузка настроек из хранилища при первом рендере таблицы
    useEffect(() => {
        const columns = getColumns()
        const initColumns = () => {
            // Второе условие предотвращает падение приложения при добавлении/удалении
            // дополнительных столбцов из-за подгрузки из кеша устаревшего набора колонок
            if (columnVisibility.current === undefined || columnVisibility.current.length !== columns.length) {
                columnVisibility.current = columns.map((item) => {
                    return {hide: item.hide}
                })
                dispatch(actions.saveSettings({
                    simCardListPage: {
                        columnVisibility: columnVisibility.current
                    }
                }))
                return columns
            } else {
                return columns.map((item, index) => {
                    return {...item, hide: columnVisibility.current && columnVisibility.current[index].hide}
                })
            }
        }
        setColumns(initColumns())
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setRows(simCardList);
        setHiddenCols(getHiddenCols())
        sortModel.length === 0 && setSortModel(defaultSortModel)
    }, [simCardList]) // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Пакетная отправка запросов для массивов данных с количеством записей выше лимита в 1000 шт
     */
    const requestSequencer = async (response: OkResponse<SimCardListResponse>) => {
        let counter = 1
        let maxCounter = 1
        let result: SimCard[] = []
        // Если общее кол-во записей совпадает с размером текущей выборки, возвращаем текущий результат
        if (response.data.list_info.total_count === response.data.list_info.records) {
            setLoading(false)
            return response.data.data.items
        } else {
            // Иначе начинаем цикл повторных запросов со смещением по счётчику
            maxCounter = Math.floor(response.data.list_info.total_count / response.data.list_info.records) + 1
        }

        result = response.data.data.items

        while (maxCounter - counter) {
            const output = await fetchSimCardList({offset: counter * 1000})
            if (output.status === "ok") {
                result = result.concat(output.data.data.items)
            }
            counter++
        }
        setLoading(false)
        return result
    }

    /**
     * Обновление настроек столбцов с учётом их видимости
     */
    const updateColsVisibility = (columnVisibilityModel: GridColumnVisibilityModel) => {

        // Индексы столбцов, тегированные их названиями {'название столбца' => 'его индекс'}
        let columnNamedIndexes = {}
        columns.map((obj) => obj.field).forEach((item, i) => {
            columnNamedIndexes[item] = i;
        })

        // Столбец, видимость которого поменялась
        let alteredColumn = {
            index: -1,
            name: '',
            hide: false
        };

        // Столбцы с трафиком: видимым может быть лишь один, либо все скрыты
        const trafficColumns = ["traffic", "trafficKb", "trafficMb", "trafficGb"]

        if (columnVisibility.current) {
            for (const columnName in columnVisibilityModel) {
                if (columnName !== "__check__") {
                    // Проверка на то, что видимость какого-то из столбцов по сравнению со стором поменялась
                    if (columnVisibilityModel[columnName] !== !columnVisibility.current[columnNamedIndexes[columnName]].hide) {
                        alteredColumn = {
                            name: columnName,
                            index: columnNamedIndexes[columnName],
                            hide: !columnVisibilityModel[columnName]
                        }

                        // Изменяем видимость столбца для стора
                        columnVisibility.current[alteredColumn.index] = {hide: !columnVisibilityModel[columnName]}

                        // Если включается видимость какого-либо из 4-х столбцов трафика,
                        // то остальные столбцы нужно отключить (эффект радиокнопки)
                        if (trafficColumns.includes(alteredColumn.name)) {
                            if (!alteredColumn.hide) {
                                for (const trafficColumn of trafficColumns) {
                                    if (trafficColumn !== alteredColumn.name) {
                                        // Сохраняем это в сторе
                                        columnVisibility.current[columnNamedIndexes[trafficColumn]] = {hide: true}
                                        // И в модели видимости столбцов
                                        columnVisibilityModel[trafficColumn] = false
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // Сохраняем конфигурацию в хранилище
        dispatch(actions.saveSettings({
            simCardListPage: {
                columnVisibility: columnVisibility.current
            }
        }))

        // Обновляем настройки столбцов и список скрытых столбцов для виджета быстрого поиска
        setColumns((prevState) => {
            if (columnVisibility.current !== undefined) {
                for (const item of prevState) {
                    prevState[columnNamedIndexes[item.field]].hide = columnVisibility.current[columnNamedIndexes[item.field]].hide
                }
            }
            setHiddenCols(getHiddenCols(prevState))
            return prevState
        })

        // Очищаем строку быстрого поиска при изменении видимости столбцов
        setSearchText('')
        setRows(getRows(simCardList) as any[])
        setColumnVisibilityModel(columnVisibilityModel)
    }

    /**
     * Список скрытых полей таблицы для виджета быстрого поиска
     */
    const getHiddenCols = (allCols: GridColDef[] = []) =>
        (allCols.length === 0 ? columns : allCols)
            .map((item) => item.hide ? item.field : null)

    /**
     * Выделенные чекбоксами строки
     */
    const getCheckboxSelectedRows = (): Partial<SimCard>[] => rows?.filter((item) => selectionModel.indexOf(item.msisdn) !== -1) || []

    return (
        <>
            <PageTitle breadcrumbs={[]}>SIM-карты</PageTitle>
            <div className="card">
                <SectionHeader/>

                <div style={{height: "80vh"}} className="card-body d-flex">
                    <div style={{flexGrow: 1}}>

                        <TopSliderMenu
                            show={!!selectionModel.length}
                            simCardCounter={selectionModel.length}
                            onClose={() => setSelectionModel([])}
                        >
                            <SetContactAssignments
                                label={'Контакты'}
                                className={'btn btn-sm btn-icon btn-color-primary btn-active-light-primary fs-5 w-100px'}
                                selectedRows={getCheckboxSelectedRows()}
                            />
                            <SetGroupAssignments
                                label={'Группы'}
                                className={'btn btn-sm btn-icon btn-color-primary btn-active-light-primary fs-5 w-80px'}
                                selectedRows={getCheckboxSelectedRows()}
                                onFinish={() => setRefreshTable(Date.now())}
                            />
                            <SetMultipleStatus
                                label={'Блокировка'}
                                className={'btn btn-sm btn-icon btn-color-primary btn-active-light-primary fs-5 w-125px'}
                                selectedRows={getCheckboxSelectedRows()}
                                onFinish={() => setRefreshTable(Date.now())}
                            />
                            <SendTestSms
                                label={'Тестовые SMS'}
                                className={'btn btn-sm btn-icon btn-color-primary btn-active-light-primary fs-5 w-125px'}
                                selectedRows={getCheckboxSelectedRows()}
                            />
                        </TopSliderMenu>

                        {message && <AlertDismissible additionalClassNames='p-5 mb-0' message={message}/>}

                        <ThemeProvider theme={theme}>
                            <DataGrid
                                autoPageSize
                                checkboxSelection
                                disableColumnMenu
                                disableSelectionOnClick
                                loading={loading}
                                columns={columns}
                                rows={getRows(rows) as Rest[]}
                                components={{
                                    Toolbar: ToolbarWithQuickSearch
                                }}
                                componentsProps={{
                                    toolbar: toolbarConfig({
                                        searchText,
                                        searchData: getRows(simCardList) as any[],
                                        hiddenCols,
                                        setRows,
                                        setSearchText,
                                        filteredSimCardList: getRows(rows)
                                    }),
                                }}
                                sortModel={sortModel}
                                onSortModelChange={(updatedSortModel) => setSortModel(updatedSortModel)}
                                selectionModel={selectionModel}
                                onSelectionModelChange={(updatedSelectionModel) => setSelectionModel(updatedSelectionModel)}
                                columnVisibilityModel={columnVisibilityModel}
                                onColumnVisibilityModelChange={(updatedColumnVisibilityModel) => updateColsVisibility(updatedColumnVisibilityModel)}
                                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                            />
                        </ThemeProvider>
                    </div>
                </div>
            </div>
        </>
    )
}
